import React from 'react'
import { navigate } from 'gatsby'

// Styles
import cn from '../index.module.sass'

// Encode Function for Data
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class WorkEnquiry extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      subject: 'Website Enquiry',
      contactPerson: '',
      phone: '',
      email: '',
      entityName: '',
      city: '',
      state: '',
      zip: '',
    }
    this.baseState = this.state
  }

  handleChange = e => {
    const {target} = e
    if(target.value.length > 0) {
      if(target.localName === 'input') {
        target.classList.add(cn.input__filled)
      }
      if(target.localName === 'textarea') {
        target.classList.add(cn.textarea__filled)
      }
    } else {
      if(target.localName === 'input') {
        target.classList.remove(cn.input__filled)
      }
      if(target.localName === 'textarea') {
        target.classList.remove(cn.textarea__filled)
      }
    }

    this.setState({ [target.name]: target.value });
  };

  validate = () => {
    const formLength = this.formEl.length
    if (this.formEl.checkValidity() === false) {
      for(let i=0; i<formLength; i++) {
        const elem = this.formEl[i]
        const errorLabel = elem.parentNode.querySelector('.'+cn.error)
        
        if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
          if (!elem.validity.valid) {
            console.log('not ', elem.validationMessage)
            errorLabel.textContent = '*' + elem.validationMessage
          } else {
            console.log('valid ', elem)
            errorLabel.textContent = ''
          }
        }
      }
      return false;
    } else {
      for(let i=0; i<formLength; i++) {
          const elem = this.formEl[i]
          const errorLabel = elem.parentNode.querySelector('.'+cn.error)
          if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
              errorLabel.textContent = ''
          }
        }
      return true
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if(this.validate()) {
      const form = e.target
      fetch('/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.state
        })
      })
      .then(() => {
        navigate('/thank-you')
      })
      .catch(error => {
        this.validate()
        this.setState({isValidated: true})
      })
    }
  }

  componentWillUnmount() {
    this.setState(this.baseState)
  }

  render() {
    const props = {...this.props};
    let classNames = [];
    
    if (props.className) {
      classNames = [...props.className];
      delete props.className;
    }

    if (this.state.isValidated) {
      classNames.push('.was-validated');
    }

    return (
      <>
        <form ref={form => this.formEl = form} onSubmit={this.handleSubmit} {...props} className={cn.form} name='Enquiry' action='/thank-you' method='POST' data-netlify='true' data-netlify-honeypot='message-bot' noValidate>
          <fieldset>
              {/* Blank input section for bug reasons */}
              <p className={cn.halfLeft} style={{display: 'none'}}>
                <input type='text' name='' id='' onChange={this.handleChange} />
                <label htmlFor=''></label>
                <span className={cn.error}/>
              </p>
              {/* *********************************** */}
              <p>
                <input type='text' name='Name' id='Name' onChange={this.handleChange} required />
                <label htmlFor='Name'>Contact Person*</label>
                <span className={cn.error}/>
              </p>
              <p className={cn.thirdLarge}>
                <input type='tel' name='phone' id='phone' onChange={this.handleChange} />
                <label htmlFor='phone'>Phone</label>
                <span className={cn.error}/>
              </p>
              <p className={cn.thirdSmall}>
                <input type='email' name='email' id='email' onChange={this.handleChange} required />
                <label htmlFor='email'>Email*</label>
                <span className={cn.error}/>
              </p>
              <p className={cn.thirdLarge__1}>
                <input type='text' name='entityName' id='entityName' onChange={this.handleChange} />
                <label htmlFor='entityName'>Church, Organization, or Company*</label>
                <span className={cn.error}/>
              </p>
              <p className={cn.thirdSmall__1}>
                <input type='text' name='city' id='city' onChange={this.handleChange} />
                <label htmlFor='city'>City*</label>
                <span className={cn.error}/>
              </p>
              <p className={`${cn.no__margin__bottom} ${cn.halfLeft}`}>
                <input type='text' name='state' id='state' onChange={this.handleChange} />
                <label htmlFor='state'>State*</label>
                <span className={cn.error}/>
              </p>
              <p className={`${cn.no__margin__bottom} ${cn.halfRight}`}>
                <input type='text' name='zip' id='zip' onChange={this.handleChange} />
                <label htmlFor='zip'>Zip Code*</label>
                <span className={cn.error}/>
              </p>
          </fieldset>
          <fieldset>
              <p style={{display: 'none'}}>
                <label>Message Bots:</label>
                <textarea name='message-bot'/>
              </p>
              <p>
                <textarea id='message' name='message' rows={11} onChange={this.handleChange} />
                <label htmlFor='message'>Enquiry Details...</label>
                <span className={cn.error}/>
              </p>
              <p>
                <button name='submit' type='submit'>Submit</button>
              </p>
          </fieldset>
        </form>
      </>
    )
  }
}