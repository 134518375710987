import React from 'react'

// Components
import cn from './index.module.sass'

const Wrapper = ({children, style}) => (
  <section className={cn.wrapper} style={style}>
    {children}
  </section>
)

export default Wrapper
