import React from 'react'
import {Link as ScrollLink} from 'react-scroll'

// Styles
import cn from './index.module.sass'

// Components
import Logo from '../../custom/Logo'

const Navbar = () => (
  <nav className={cn.nav}>
    <ul className={cn.list}>
      <li className={cn.item}><Logo/></li>
      <li className={cn.item}><ScrollLink name='go to about section' ignoreCancelEvents={true} className={cn.link} offset={0} smooth={'easeInOutQuad'} duration={1300} to='about'>About</ScrollLink></li>
      <li className={cn.item}><ScrollLink name='go to music section' ignoreCancelEvents={true} className={cn.link} offset={0} smooth={'easeInOutQuad'} duration={1300} to='music'>Music</ScrollLink></li>
      <li className={cn.item}><ScrollLink name='go to contact form' ignoreCancelEvents={true} className={cn.link} offset={0} smooth={'easeInOutQuad'} duration={1300} to='contact'>Contact</ScrollLink></li>
    </ul>
  </nav>
)

export default Navbar
