import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faSpotify, faPatreon } from '@fortawesome/free-brands-svg-icons'
import ReactTooltip from 'react-tooltip'

// Styles
import cn from './index.module.sass'

// Images
import imgLogo from '../../../images/logos/andrew-harper-music-logo-white-design-orlando-florida.png'
import GetInTouch from '../../forms/GetInTouch'
import WorkEnquiry from '../../forms/WorkEnquiry'

// Components


const links = {
  facebook: 'https://www.facebook.com/AndrewHarperMusic/',
  instagram: 'https://www.instagram.com/_andrewharpermusic/',
  spotify: 'https://open.spotify.com/album/5rA4guMvc42uvCBZtvnuxy?si=13-gMcESRKK_surLnzMsbQ',
  patreon: 'https://www.patreon.com/AndrewHarperMusic',
}

const showContact = (e) => {
  const formContainer = document.querySelector('.'+cn.container__forms)
  formContainer.classList = `${cn.container__forms} ${cn.form__contact}`
  const buttons = document.querySelectorAll('.'+cn.btn)
  buttons[0].classList.add(cn.btn__selected)
  buttons[1].classList.remove(cn.btn__selected)
}

const showEnquiry = (e) => {
  const formContainer = document.querySelector('.'+cn.container__forms)
  formContainer.classList = `${cn.container__forms} ${cn.form__enquiry}`
  const buttons = document.querySelectorAll('.'+cn.btn)
  buttons[0].classList.remove(cn.btn__selected)
  buttons[1].classList.add(cn.btn__selected)
}

const Footer = () => (
  <>
    <ReactTooltip effect='solid' />
    <section id='contact' className={cn.container__contact}>
      <section className={cn.wrapper__contact}>
        <h4 className={cn.title}>Let's Connect</h4>
        <a className={cn.link} href={links.facebook}>
          <FontAwesomeIcon data-tip='Facebook' className={cn.icon} icon={faFacebookF} />
        </a>
        <a className={cn.link} href={links.instagram}>
          <FontAwesomeIcon data-tip='Instagram' className={cn.icon} icon={faInstagram} />
        </a>
        <a className={cn.link} href={links.spotify}>
          <FontAwesomeIcon data-tip='Spotify' className={cn.icon} icon={faSpotify} />
        </a>
        <a className={cn.link} href={links.patreon}>
          <FontAwesomeIcon data-tip='Patreon' className={`${cn.icon} ${cn.icon__patreon}`} icon={faPatreon} />
        </a>
      </section>
    </section>
    <section className={cn.container__footer}>
      <section className={cn.wrapper__footer}>
        <button onClick={showContact} className={`${cn.btn} ${cn.btn__selected}`}><span>Get in Touch</span></button>
        <button onClick={showEnquiry} className={cn.btn}><span>Work Enquiry</span></button>
        <section className={`${cn.container__forms} ${cn.form__contact}`}>
          <GetInTouch/>
          <WorkEnquiry/>
        </section>
        <img className={cn.logo} src={imgLogo} alt="andrew harper music logo"/>
        <p className={cn.copyright}>Copyright <i>Andrew Harper Music</i> © 2018</p>
      </section>
    </section>
  </>
)

export default Footer