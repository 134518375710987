import React from 'react'
import Fade from 'react-reveal/Fade'

// Components
import cn from './index.module.sass'

const SectionTitle = ({title, subtitle, style, bottom, right, small, whiteBorder, whiteText}) => (
  <Fade bottom={bottom ? true : false} top={bottom ? false : true}>
    <section className={`${bottom ? cn.container__bottom : cn.container__top} ${right ? cn.right : ''} ${small ? cn.small : ''} ${whiteBorder ? cn.whiteBorder : ''}`} style={style}>
      {title &&
        <div className={cn.wrapper}>
          <h2 className={`${cn.title} ${whiteText ? cn.whiteText : ''}`}>{title}</h2>
          <h3 className={`${cn.subtitle} ${whiteText ? cn.whiteText : ''}`}>{subtitle}</h3>
        </div>
      || subtitle &&
        <h3 className={`${cn.subtitle} ${whiteText ? cn.whiteText : ''}`}>{subtitle}</h3> 
      }
    </section>
  </Fade>
)

export default SectionTitle
