import React from 'react'

// Styles
import cn from './index.module.sass'

// Components
import Container from '../../layout/Container'
import Wrapper from '../../layout/Wrapper'
import SectionTitle from '../../custom/SectionTitle'

const Header = () => (
  <Container cName={cn.background}>
    <Wrapper>
      <section className={cn.container}>
        <section className={cn.wrapper}>
          <h1 className={cn.title}>Worship<br/><span className={cn.title__small}>For the Glory of</span> God</h1>
          <h4 className={cn.subtitle}>by ANDREW HARPER</h4>
        </section>
      </section>
      <SectionTitle subtitle='Ways to Listen' style={{background: 'rgba(0,0,0,0.6)'}} right bottom small whiteText/>
    </Wrapper>
  </Container>
)

export default Header