import React from 'react'
import {animateScroll as scroll} from 'react-scroll'

// Images
import imgLogo from "../../../images/logos/andrew-harper-music-logo-black-orlando-florida.png";

// Components
import cn from './index.module.sass'

const scrollToTop = () => {
  scroll.scrollToTop()
}

const Logo = (link) => (
  <button onClick={scrollToTop} className={cn.container}>
    <div className={cn.square__outer}/>
    <div className={cn.square__inner}/>
    <img className={cn.logo} src={imgLogo} alt='Andrew Harper Music Black'/>
    <img className={cn.logo} src={imgLogo} alt='Andrew Harper Music Orange'/>
  </button>
)

export default Logo
