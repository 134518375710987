import React from 'react'

// Components
import cn from './index.module.sass'

const Container = ({children, id, cName, style}) => (
  <section id={id} className={`${cn.container} ${cName ? cName : ''}`} style={style}>
    {children}
  </section>
)

export default Container
