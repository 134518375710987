import React from 'react'
import { navigate } from 'gatsby'

// Styles
import cn from '../index.module.sass'

// Encode Function for Data
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class GetInTouch extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      subject: 'Website Contact Form',
      first: '',
      last: '',
      phone: '',
      email: '',
      message: '',
    }
    this.baseState = this.state
  }

  handleChange = e => {
    const {target} = e
    if(target.value.length > 0) {
      if(target.localName === 'input') {
        target.classList.add(cn.input__filled)
      }
      if(target.localName === 'textarea') {
        target.classList.add(cn.textarea__filled)
      }
    } else {
      if(target.localName === 'input') {
        target.classList.remove(cn.input__filled)
      }
      if(target.localName === 'textarea') {
        target.classList.remove(cn.textarea__filled)
      }
    }

    this.setState({ [target.name]: target.value });
  };

  validate = () => {
    const formLength = this.formEl.length
    if (this.formEl.checkValidity() === false) {
      for(let i=0; i<formLength; i++) {
        const elem = this.formEl[i]
        const errorLabel = elem.parentNode.querySelector('.'+cn.error)
        
        if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
          if (!elem.validity.valid) {
            console.log('not ', elem.validationMessage)
            errorLabel.textContent = '*' + elem.validationMessage
          } else {
            console.log('valid ', elem)
            errorLabel.textContent = ''
          }
        }
      }
      return false;
    } else {
      for(let i=0; i<formLength; i++) {
          const elem = this.formEl[i]
          const errorLabel = elem.parentNode.querySelector('.'+cn.error)
          if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
              errorLabel.textContent = ''
          }
        }
      return true
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if(this.validate()) {
      const form = e.target
      fetch('/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.state
        })
      })
      .then(() => {
        navigate('/thank-you')
      })
      .catch(error => {
        this.validate()
        this.setState({isValidated: true})
      })
    }
  }

  componentWillUnmount() {
    this.setState(this.baseState)
  }

  render() {
    const props = {...this.props};
    let classNames = [];
    
    if (props.className) {
      classNames = [...props.className];
      delete props.className;
    }

    if (this.state.isValidated) {
      classNames.push('.was-validated');
    }

    return (
      <>
        <form ref={form => this.formEl = form} onSubmit={this.handleSubmit} {...props} className={cn.form} name='Contact' action='/thank-you' method='POST' data-netlify='true' data-netlify-honeypot='message-bot' noValidate>
          <fieldset>
            {/* Blank input section for bug reasons */}
            <p className={cn.halfLeft} style={{display: 'none'}}>
              <input type='text' name='' id='' onChange={this.handleChange} />
              <label htmlFor=''></label>
              <span className={cn.error}/>
            </p>
            {/* *********************************** */}
            <p className={cn.halfLeft}>
              <input type='text' name='fname' id='fname' onChange={this.handleChange} required />
              <label htmlFor='fname'>First Name*</label>
              <span className={cn.error}/>
            </p>
            <p className={cn.halfRight}>
              <input type='text' name='lname' id='lname' onChange={this.handleChange} required />
              <label htmlFor='lname'>Last Name*</label>
              <span className={cn.error}/>
            </p>
            <p className={`${cn.no__margin__bottom} ${cn.thirdLarge}`}>
              <input type='tel' name='phone' id='phone' onChange={this.handleChange} />
              <label htmlFor='phone'>Phone</label>
              <span className={cn.error}/>
            </p>
            <p className={`${cn.no__margin__bottom} ${cn.thirdSmall}`}>
              <input type='email' name='email' id='email' onChange={this.handleChange} required />
              <label htmlFor='email'>Email*</label>
              <span className={cn.error}/>
            </p>
          </fieldset>
          <fieldset>
              <p style={{display: 'none'}}>
                <label>Message Bots:</label>
                <textarea name='message-bot'/>
              </p>
              <p>
                <textarea id='message' name='message' rows={11} onChange={this.handleChange} />
                <label htmlFor='message'>Message...</label>
                <span className={cn.error}/>
              </p>
              <p>
                <button name='submit' type='submit'>Submit</button>
              </p>
          </fieldset>
        </form>
      </>
    )
  }
}