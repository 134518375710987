import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

// Styles
import './global.sass'

// Components
import Header from './globals/Header'
import Navbar from './globals/Navbar'
import Footer from './globals/Footer'

const Layout = ({ children }) => (
  <>
    <Helmet>
      <html lang="en" />
    </Helmet>
    <Navbar/>
    <Header/>
    {children}
    <Footer/>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout